.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

div {
  /* color: 'red' !important; */
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

.bg-blue {
  background-color: #233f78;
}

.message_error_container {
  position: fixed;
  bottom: 4vh;
  right: 10px;
  left: 10px;
  padding: 4px;
  z-index: 2;
  text-align: center;
}
.message_error_container span {
  color: #ffff;
  padding: 10px 20px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  border: 1px dotted white;
}
.message_error_container .message_text {
  background-color: #44A1A0;
}
.message_error_container .error_text {
  background-color: #B23A48;
}
.small_text {
  font-size: 14px;
}

.mainLoaderContainer {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 10;
  background-color: rgba(0,0,0,0.4);
  padding-top: 24%;
  align-items: center;
}
.labelText {
  font-size: 12px;
  color: black;
  text-align: left !important;
  margin-bottom: 5px;
  margin-top: 10px;
}
.errorMessage {
  font-size: 13px;
  color: #ef5350;
}

.messageText {
  font-size: 13px;
  color: green;
}

.headerTitle {
  margin-top: 10px;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
}
.dayCard {
  background-color: #44a1a0;
  box-shadow: 5px 4px 10px #888888;
  cursor: pointer;
}
.inActiveDayCard {
  background-color: grey;
}
.dayImage {
  height: 100px;
  width: 100px;
}
.dayTitle{
  margin-top: 10px;
  font-size: 33px;
  color: #fff;
  text-transform: uppercase;
}
.taskImage {
  height: 66px;
  width: 66px;
}
.taskTitle{
  margin-top: 10px;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
}
.noResults {
  margin-top: 10px;
  font-size: 24px;
  color: #000;
  text-transform: uppercase;
}
.backArrowContainer {
  text-align: left;
  margin-top: 20px;
  cursor: pointer;
}
.inActiveOption {
  color: 'black';
  background-color: lightgray;
}

.backText {
  margin-top: -24px;
  margin-left: 35px;
  font-size: 16px;
  color: #000;
}
.imageDropContainer {
  padding: 30px 20px;
  margin-top: 20px;
}
.imageDropView {
  height: 100px;
  padding: auto;
  border: 1px solid white;
  justify-content: center;
  align-items: center;
}
.imageDropBackgroundView {
  height: 200px;
  padding: auto;
  border: 1px solid white;
  justify-content: center;
  align-items: center;
}
.imageDropOverlapView {
  /* border: 1px solid white; */
  position: absolute;
  top: 31%;
  left: 41%;
  z-index: 10;
  height: 88px;
  width: 88px;
  padding: auto;
  justify-content: center;
  align-items: center;
}
.droppedBackgroundImage {
  height: 200px;
  width: auto;
}
.droppedOverlapImage {
  height: 88px;
  width: auto;
}
.droppedImage {
  height: 80px;
  width: auto;
}
.removeImageIcon {
  position: absolute;
  top: -10px;
  right: 10px;
  z-index: 10;
}
.fixitImageDropContainer {
  /* background-color: whitesmoke; */
  /* padding: 30px 20px; */
}
.fixitImageDropView {
  min-height: 500px;
  width: 100%;
  background-color: white;
  /* justify-content: center;
  align-items: center; */
}
.fixitDroppedImage {
  height: 100px;
  width: 100px;
  /* object-fit: cover;
  background-color: gold;
  padding: 10px;
  cursor: move;
  z-index: 10; */
  /* background-color: transparent; */
  /* color: transparent; */
}
.fixitImageRemoveIcon {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10;
}

.draggableView {
  position: absolute;
  z-index: 9;
  background-color: transparent;
  text-align: center;
  border: 1px solid transparent;
}

.draggableView img {
  cursor: pointer;
  z-index: 10;
}

a:hover {
  text-decoration: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.BgImage{
  background-image:url('./assets/images/bgImage6.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}
.paper{
  margin-top: 8%;
  margin-left: 50% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avatar{
  margin: 10px;
  background-color: red !important;
  align-items: center !important;
  margin-left: 45%;
}
.form {
  width: '100%';
  margin-top: 30px;
}
.submit {
  margin: 3 0 2;
}
.AppHeader{
padding: 1%;
color: whitesmoke;
}
.SideMenu{
  background-color: black;
color: whitesmoke;
}
.sidebar {
  margin: 0;
  padding: 0;
  width: 25%;
  background-color:white;
  position: fixed;
  height: auto;
  overflow: auto;
  z-index: 9999;
}
 
.sidebar .link_active {
  /* background-color: #7f5a83;
background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);
  color: white;
  box-shadow: 5px 4px 10px #888888; */
  color: #ffff;
  font-weight: 600 !important;
  background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);
}

.sidebar a:hover:not(.link_active) {
  background-color: #7f5a83;
background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);
  color: white;
  box-shadow: 5px 4px 10px #888888;
}
.MenuNavLinks{
  /* background-image: linear-gradient(315deg, #7f5a83b0 0%, #0d324da3 74%); */
  /* box-shadow: 5px 4px 10px #888888; */
  border: 1px solid #7f5a83;
  color: #7f5a83;
  font-weight: 900;
  /* color: white; */
}

.MenuNavLink:hover {
  /* background-image: linear-gradient(315deg, #7f5a83b0 0%, #0d324da3 74%); */
  /* box-shadow: 5px 4px 10px #888888; */
  /* background-color: #7f5a83 !important;
  color: #ffff;
  font-weight: 900; */
  background-color: black;
  /* color: white; */
}

div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
}
@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar .link_active {float: left;}
  div.content {margin-left: 0;}
}

@media screen and (max-width: 400px) {
  .sidebar .link_active {
    text-align: center;
    float: none;
  }
}
.ProfileCard{
  background-color: #7f5a83;
  background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);
}
.CardImage{
  width: 120px;
  height: 120px;
  margin: auto;
}
.MainContainer{
  height: 88vh;
  overflow: scroll;
}
.SideMenuLinks{
  height: 73vh;
  overflow: scroll;
}
.ProfileTabsDiv{
display: flex;
}
.ProfileTab{
  margin-left: 2% !important;
}
.HomeCardImage{
  width: auto;
  height: 200px;
  background-color: #7f5a83;
background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);
}
.activeView{
  background-color: blue;
  color: white;
  /* border: 1px solid blue; */
}
.successView{
  background-color: green;
  color: white;
  /* border: 1px solid green; */
}
.warningView{
  background-color: yellow;
  color: #000000;
  /* border: 1px solid yellow; */
}
.lightRadius{
  border-radius: 5px;
}

/* Home Page Styles */

.card-counter{
  box-shadow: 2px 2px 10px #DADADA;
  margin: 5px;
  padding: 20px 10px;
  background-color: #fff;
  height: 100px;
  border-radius: 5px;
  transition: .3s linear all;
}

.card-counter:hover{
  box-shadow: 4px 4px 20px #DADADA;
  transition: .3s linear all;
}

.card-counter.primary{
  background-color: #007bff;
  color: #FFF;
}

.card-counter.danger{
  background-color: #ef5350;
  color: #FFF;
}  

.card-counter.success{
  background-color: #66bb6a;
  color: #FFF;
}  

.card-counter.info{
  background-color: #26c6da;
  color: #FFF;
}  

.card-counter .faIcon{
  font-size: 4em;
    opacity: 0.2;
    float: left;
}

.card-counter .count-numbers{
  position: absolute;
  right: 35px;
  top: 20px;
  font-size: 32px;
  display: block;
}

.card-counter .count-name{
  position: absolute;
  right: 35px;
  top: 65px;
  font-style: italic;
  text-transform: capitalize;
  opacity: 0.5;
  display: block;
  font-size: 18px;
}
.SurveyAnswers{
  font-size: 12px;
}
.leftCharacters{
  font-size: 13px;
  color: #f37421;
  font-weight: 500;
}
.standardImagesContainer{
  margin-top: 10px;
  display: inline-block;
  justify-content: space-between;
  padding: 10px 0px;
}
.standardImage{
  max-height: 180px;
  margin: 0px 20px 10px;
}
.dimensionImage{
  height: 260px;
  width: 40%;
  margin: 6px auto;
}
.nameNarrateImage {
  height: 150px;
  width: 60%;
  margin: 20px auto;
}
.errorMessage{
  font-size: 13px;
  color: tomato;
  font-weight: 500;
}
.messageTex {
  font-size: 13px;
  color: green;
}
.previewImage{
  max-height: 250px;
}


/* Remove arrow keys from number inputs */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Piano SVG Style */
.cls-1 {
  fill: #00d3d1;
  stroke: #16b4b2;
  stroke-width: 16px;
}

.cls-1, .cls-14, .cls-2, .cls-3, .cls-5 {
  stroke-linejoin: round;
}

.cls-1, .cls-14, .cls-2, .cls-4, .cls-5 {
  fill-rule: evenodd;
}

.cls-2 {
  fill: #009d9b;
  stroke: #565656;
  stroke-opacity: 0.25;
  stroke-width: 24px;
}

.cls-3 {
  fill: #e8e000;
  stroke: #000;
  stroke-width: 3px;
}

.cls-5 {
  fill: #e5e5e5;
  stroke: #bcbcbc;
}

.cls-14, .cls-5 {
  stroke-width: 7px;
}

.cls-6 {
  fill: #b1356f;
}

.cls-7 {
  fill: #c14830;
}

.cls-8 {
  fill: #cba12f;
}

.cls-9 {
  fill: #67d000;
}

.cls-10 {
  fill: #3fa727;
}

.cls-11 {
  fill: #24aecc;
}

.cls-12 {
  fill: #4573c0;
}

.cls-13 {
  fill: #785bb2;
}

.cls-14 {
  fill: #c7b299;
  stroke: #9d8f8f;
  filter: url(#filter);
}

/* Terms and conditions */
.terms_main_container {
  background-color: lightgray;
}
.header {
  color: #233f78;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
}
.para {
  font-size: 14px;
  color: #172a3a;
  text-align: justify;
}
.description_card {
  padding: 20px;
}
/* .terms_container .description_card div {
  padding: 20px 30px;
} */
.terms_container .description_card:nth-child(odd) {
  background: white;
}
.terms_container .description_card:nth-child(even) {
  background: whitesmoke;
}

.payButton {
  color: #ffffff;
  border: 1px solid transparent;
}
.payButton:hover {
  color: #ffffff;
  border: 1px solid white;
  box-shadow: 0px 2px darkgray;
}
.payButton1 {
  
  background-color: purple;
}
.payButton2 {
  background-color: #f37421;
}
.payButton3 {
  background-color: red;
}

@media screen and (max-width:768px) {
  .description_card {
      padding: 20px !important;
  }
  .description_card div {
      padding: 10px 15px !important;
  }
}
