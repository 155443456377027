/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2){

    .BgImage{
        background-image:url('../src/assets/images/bgImage5.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .paper{
        margin-top: 10%;
        margin-left: 0% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .avatar{
        margin: 10px;
        background-color: red !important;
        align-items: center !important;
        margin-left: 45%;
      }
      .form {
        width: '100%';
        margin-top: 30px;
      }
      .submit {
        margin: 3 0 2;
      }
      .MuiGrid-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        font-size: 11px;
        box-sizing: border-box;
        margin-top: 3%;
    }

}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media screen and (min-width: 375px) and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) { 

    .BgImage{
        background-image:url('../src/assets/images/bgImage5.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .paper{
        margin-top: 30%;
        margin-left: 0% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .avatar{
        margin: 10px;
        background-color: red !important;
        align-items: center !important;
        margin-left: 45%;
      }
      .form {
        width: '100%';
        margin-top: 30px;
      }
      .submit {
        margin: 3 0 2;
      }
      .MuiGrid-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        font-size: 13px;
        box-sizing: border-box;
        margin-top: 3%;
    }

}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

    .BgImage{
        background-image:url('../src/assets/images/bgImage5.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .paper{
        margin-top: 30%;
        margin-left: 0% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .avatar{
        margin: 10px;
        background-color: red !important;
        align-items: center !important;
        margin-left: 45%;
      }
      .form {
        width: '100%';
        margin-top: 30px;
      }
      .submit {
        margin: 3 0 2;
      }
      .MuiGrid-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        font-size: 13px;
        box-sizing: border-box;
        margin-top: 3%;
    }

}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

    .BgImage{
        background-image:url('../src/assets/images/bgImage5.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .paper{
        margin-top: 30%;
        margin-left: 0% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .avatar{
        margin: 10px;
        background-color: red !important;
        align-items: center !important;
        margin-left: 45%;
      }
      .form {
        width: '100%';
        margin-top: 30px;
      }
      .submit {
        margin: 3 0 2;
      }
      .MuiGrid-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        font-size: 13px;
        box-sizing: border-box;
        margin-top: 3%;
    }
      
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

    .BgImage{
        background-image:url('../src/assets/images/bgImage5.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .paper{
        margin-top: 30%;
        margin-left: 20% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .avatar{
        margin: 10px;
        background-color: red !important;
        align-items: center !important;
        margin-left: 45%;
      }
      .form {
        width: '100%';
        margin-top: 30px;
      }
      .submit {
        margin: 3 0 2;
      }
      .MuiGrid-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        font-size: 13px;
        box-sizing: border-box;
        margin-top: 3%;
    }

}